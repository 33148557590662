import React, {useCallback, useState} from 'react';
import {
    Box,
    Button,
    List,
    ListItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {CreateRaidAndAssignPlayers} from "../Services/AssignPlayers";

export const GeneratedTeam = ({playerList, roles}) => {
    let [raiders, setRaiders] = useState(CreateRaidAndAssignPlayers(playerList, roles));
    console.log(raiders);
    let rollFunction = useCallback((playerList) => {
        setRaiders([]);
        setRaiders(CreateRaidAndAssignPlayers(playerList, roles));
    },  [setRaiders, roles]);
    console.log(raiders);
    return (
        <Box flex={1} flexDirection={"column"} justifyContent={"center"} maxWidth={800} sx={{margin: "auto"}}>
            <TableContainer sx={{justifyContent: "center"}}>
                <Table sx={{ minWidth: 600}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Player</TableCell>
                            <TableCell>Main</TableCell>
                            <TableCell>New Class and Specialization</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {raiders.map((row) => (
                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell><MainList mains={row.main}/></TableCell>
                                <TableCell>{row.classAndSpec.textName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{margin: "150"}}>
                <Button onClick={() => rollFunction(raiders)} variant={'contained'}>Reroll!</Button>
            </Box>
        </Box>
    )
}


const MainList = ({mains}) => {
    return <List sx={{padding: 0}}>
        {mains.map(main => <ListItem sx={{padding: 0}}>{main}</ListItem>)}
    </List>
}

