import React from 'react';
import {useFormik} from 'formik';
import {Box, Button, Input, InputLabel, MenuItem, Select} from "@mui/material";
import {Healers, Melees, Ranged, Tanks} from "../SpecAndClasses/SpecAndClasses";


export const RaidInput = ({submitHandler, raidSize}) => {
    let raiderList = CreateEmptyRaidObject(raidSize);
    const {handleSubmit,handleChange, handleBlur, values} = useFormik({
       onSubmit: (values) => {
           submitHandler(mapToPlayer(raidSize, values));
       },
       initialValues: raiderList
    });
    return (
        <div>
            <Box component={"form"} onSubmit={evt=> handleSubmit(evt)}>
                {createInputs(raidSize, handleChange, handleBlur, values)}
                <Button
                    type={"submit"}
                    variant={'contained'}
                    style={{
                        margin: "2.5rem"
                    }}
                >
                    Save
                </Button>
            </Box>
        </div>
    )
}

const mapToPlayer = (raidSize, values) => {
    let response = [];
    for ( let i = 0; i < raidSize; i++) {
        response.push({
            name: values[`name${i}`],
            main: values[`main${i}`]
        });
    }
    return response;
}

const createInputs = (count, handleChange, handleBlur, values) => {
    let inputs = [];
    for(let i = 0; i < count; i++) {
        inputs.push(CreateInputLine(i, handleChange, handleBlur, values));
    }
    return (
        <div>
            {inputs}
        </div>
    )
}

const CreateInputLine = (index, handleChange, handleBlur, values) => {
    return (
        <Box id={`inputline-${index}`} flex={1} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
            <CreateNameInput index={index} handleChange={handleChange}/>
            <CreateMainInput index={index} handleChange={handleChange} handleBlur={handleBlur} values={values}/>
        </Box>
    )
}

const CreateNameInput = ({index, handleChange}) => {
    return (
        <Box minWidth={250}
             style={{
                 margin: "1rem"
             }}
        >
          <InputLabel>Name</InputLabel>
          <Input id={`name${index}`}
                 name={`name${index}`}
                 fullWidth
                 onChange={handleChange}
                 style={{
                     margin: "1rem"
                 }}
                 autoComplete={"off"}
                 size={"medium"}
          />
      </Box>
    );
}

const CreateMainInput = ({index, handleChange, handleBlur, values}) => {
    return (
        <Box minWidth={250}
             style={{
                 margin: "1rem"
             }}
        >
            <InputLabel>Main</InputLabel>
            <Select id={`main${index}`}
                    name={`main${index}`}
                    fullWidth
                    multiple
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant={"filled"}
                    value={values[`main${index}`]}
            >
                {[
                    ...GetSortedSelectorList().map((o) => selectionItem(o.classAndSpec,o.textName,o.textName))
                ]}
            </Select>
        </Box>
    )
}

const selectionItem = (key, value, display) => {
    return <MenuItem key={key} value={value}>{display}</MenuItem>;
};

export const GetSortedSelectorList = () => {
    let allValues = [];
    allValues.push(...Tanks);
    allValues.push(...Healers);
    allValues.push(...Melees);
    allValues.push(...Ranged);
    return allValues.sort(compare);
}

function compare( a, b ) {
    if ( a.className === b.className){
        if ( a.classAndSpec < b.classAndSpec ){
            return -1;
        }
        if ( a.classAndSpec > b.classAndSpec ){
            return 1;
        }
        return 0;
    }
    if ( a.className < b.className ){
        return -1;
    }
    if ( a.className > b.className ){
        return 1;
    }
    return 0;
}

const CreateEmptyRaidObject = (size) => {
    let response = {};
    for ( let i = 0; i < size; i++) {
        response[`name${i}`] = "";
        response[`main${i}`] = [];
    }
    console.log(`response ${response}`);
    return response;
};
