export const Healers = [
    { classAndSpec: "DisciplinePriest", textName: "Discipline Priest", className: "Priest", dropdownText: "Priest - Discipline", role: "Healer"},
    { classAndSpec: "HolyPaladin", textName: "Holy Paladin", className: "Paladin", dropdownText: "Paladin - Holy", role: "Healer"},
    { classAndSpec: "HolyPriest", textName: "Holy Priest", className: "Priest", dropdownText: "Priest - Holy", role: "Healer"},
    { classAndSpec: "MistweaverMonk", textName: "Mistweaver Monk", className: "Monk", dropdownText: "Monk - Mistweaver", role: "Healer"},
    { classAndSpec: "PreservationEvoker", textName: "Preservation Evoker", className: "Evoker", dropdownText: "Evoker - Preservation", role: "Healer"},
    { classAndSpec: "RestorationDruid", textName: "Restoration Druid", className: "Druid", dropdownText: "Druid - Restoration", role: "Healer"},
    { classAndSpec: "RestorationShaman", textName: "Restoration Shaman", className: "Shaman", dropdownText: "Shaman - Restoration", role: "Healer"},
];
export const BloodDeathKnight = { classAndSpec: "BloodDeathKnight", textName: "Blood Death Knight", className: "DeathKnight", dropdownText: "Death Knight - Blood", role: "Tank"};
export const BrewmasterMonk = { classAndSpec: "BrewmasterMonk", textName: "Brewmaster Monk", className: "Monk", dropdownText: "Monk - Brewmaster", role: "Tank"};
export const GuardianDruid = { classAndSpec: "GuardianDruid", textName: "Guardian Druid", className: "Druid", dropdownText: "Druid - Guardian", role: "Tank"};
export const ProtectionPaladin = { classAndSpec: "ProtectionPaladin", textName: "Protection Paladin", className: "Paladin", dropdownText: "Paladin - Protection", role: "Tank"};
export const ProtectionWarrior = { classAndSpec: "ProtectionWarrior", textName: "Protection Warrior", className: "Warrior", dropdownText: "Warrior - Protection", role: "Tank"};
export const VengeanceDemonHunter = { classAndSpec: "VengeanceDemonHunter", textName: "Vengeance Demon Hunter", className: "DemonHunter", dropdownText: "Demon Hunter - Vengeance", role: "Tank"};
export const Tanks = [
    BloodDeathKnight,
    BrewmasterMonk,
    GuardianDruid,
    ProtectionPaladin,
    ProtectionWarrior,
    VengeanceDemonHunter,
];


export const Melees = [
    { classAndSpec: "ArmsWarrior", textName: "Arms Warrior", className: "Warrior", dropdownText: "Warrior - Arms", role: "Melee"},
    { classAndSpec: "AssassinationRogue", textName: "Assassination Rogue", className: "Rogue", dropdownText: "Rogue - Assassination", role: "Melee"},
    { classAndSpec: "EnhancementShaman", textName: "Enhancement Shaman", className: "Shaman", dropdownText: "Shaman - Elemental", role: "Melee"},
    { classAndSpec: "FeralDruid", textName: "Feral Druid", className: "Druid", dropdownText: "Druid - Feral", role: "Melee"},
    { classAndSpec: "FrostDeathKnight", textName: "Frost Death Knight", className: "DeathKnight", dropdownText: "Death Knight - Frost", role: "Melee"},
    { classAndSpec: "FuryWarrior", textName: "Fury Warrior", className: "Warrior", dropdownText: "Warrior - Fury", role: "Melee"},
    { classAndSpec: "HavocDemonHunter", textName: "Havoc Demon Hunter", className: "DemonHunter", dropdownText: "Demon Hunter - Havoc", role: "Melee"},
    { classAndSpec: "OutlawRogue", textName: "Outlaw Rogue", className: "Rogue", dropdownText: "Rogue - Outlaw", role: "Melee"},
    { classAndSpec: "RetributionPaladin", textName: "Retribution Paladin", className: "Paladin", dropdownText: "Paladin - Retribution", role: "Melee"},
    { classAndSpec: "SubtletyRogue", textName: "Subtlety Rogue", className: "Rogue", dropdownText: "Rogue - Subtlety", role: "Melee"},
    { classAndSpec: "SurvivalHunter", textName: "Survival Hunter", className: "Hunter", dropdownText: "Hunter - Survival", role: "Melee"},
    { classAndSpec: "UnholyDeathKnight", textName: "Unholy Death Knight", className: "DeathKnight", dropdownText: "Death Knight - Unholy", role: "Melee"},
    { classAndSpec: "WindwalkerMonk", textName: "Windwalker Monk", className: "Monk", dropdownText: "Monk - Windwalker", role: "Melee"},
];

export const Ranged = [
    { classAndSpec: "AfflictionWarlock", textName: "Affliction Warlock", className: "Warlock", dropdownText: "Warlock - Affliction", role: "Ranged"},
    { classAndSpec: "ArcaneMage", textName: "Arcane Mage", className: "Mage", dropdownText: "Mage - Arcane", role: "Ranged"},
    { classAndSpec: "AugmentationEvoker", textName: "Augmentation Evoker", className: "Evoker", dropdownText: "Evoker - Augmentation", role: "Ranged"},
    { classAndSpec: "BalanceDruid", textName: "Balance Druid", className: "Druid", dropdownText: "Druid - Balance", role: "Ranged"},
    { classAndSpec: "BeastMasteryHunter", textName: "Beast Mastery Hunter", className: "Hunter", dropdownText: "Hunter - Beast Mastery", role: "Ranged"},
    { classAndSpec: "DemonologyWarlock", textName: "Demonology Warlock", className: "Warlock", dropdownText: "Warlock - Demonology", role: "Ranged"},
    { classAndSpec: "DestructionWarlock", textName: "Destruction Warlock", className: "Warlock", dropdownText: "Warlock - Destruction", role: "Ranged"},
    { classAndSpec: "DevastationEvoker", textName: "Devastation Evoker", className: "Evoker", dropdownText: "Evoker - Devastation", role: "Ranged"},
    { classAndSpec: "ElementalShaman", textName: "Elemental Shaman", className: "Shaman", dropdownText: "Shaman - Elemental", role: "Ranged"},
    { classAndSpec: "FireMage", textName: "Fire Mage", className: "Mage", dropdownText: "Mage - Fire", role: "Ranged"},
    { classAndSpec: "FrostMage", textName: "Frost Mage", className: "Mage", dropdownText: "Mage - Frost", role: "Ranged"},
    { classAndSpec: "MarksmanshipHunter", textName: "Marksmanship Hunter", className: "Hunter", dropdownText: "Hunter - Marksman", role: "Ranged"},
    { classAndSpec: "ShadowPriest", textName: "Shadow Priest", className: "Priest", dropdownText: "Priest - Shadow", role: "Ranged"},
];
