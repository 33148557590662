import React from 'react';
import {useFormik} from 'formik';
import {Box, Button, InputLabel, MenuItem, Select} from '@mui/material';


export const RaidGenerationSettings = ({changeRaidSize}) => {
    const {handleSubmit,handleChange, handleBlur, values} = useFormik({
        onSubmit: (values) => {
            console.log(values);
            changeRaidSize(values.raidCount, values.roles);
        },
        initialValues: { raidCount: 0, roles: getRoleSelectorList()}
    });
    const roleRestriction = values.raidCount === 1 ? <Box margin={'auto'} maxWidth={'10rem'}>

        <InputLabel>RoleRestriction</InputLabel>
        <Select id={`roleSelect`}
                name={`roles`}
                onChange={handleChange}
                onBlur={handleBlur}
                multiple
                defaultValue={values.roles}
                value={values.roles}
                fullWidth
        >
            {[
                ...getRoleSelectorList().map((o) => <MenuItem key={o} value={o}>{o}</MenuItem>)
            ]}
        </Select>
    </Box> : <div/>;

    return (
        <Box minWidth={'12.5rem'}
             style={{
                 margin: '1rem'
             }}
             component={'form'}
             flex={1}
             flexDirection={'column'}
             justifyContent={'center'}
             onSubmit={evt => handleSubmit(evt)}
        >
            <Box margin={'auto'} maxWidth={'10rem'}>
                <InputLabel>Group Size</InputLabel>
                <Select id={`raidCount`}
                        name={`raidCount`}
                        defaultValue={0}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                >
                    {[
                        ...getSelectorList().map((o) => selectionItem(o.key,o.value))
                    ]}
                </Select>
            </Box>
            {roleRestriction}
            <Button
                type={'submit'}
                variant={'contained'}
                style={{
                    margin: '2.5rem'
                }}
                size={'large'}
            >
                Save
            </Button>
        </Box>
    );
}

const getRoleSelectorList = () => {
    return ["Healer", "Melee", "Ranged", "Tank"];
}

const getSelectorList = () => {
    return [
        { key: 'zero', value: 0},
        { key: 'one', value: 1},
        { key: 'five', value: 5},
        { key: 'ten', value: 10},
        { key: 'eleven', value: 11},
        { key: 'twelve', value: 12},
        { key: 'thirteen', value: 13},
        { key: 'fourteen', value: 14},
        { key: 'fifteen', value: 15},
        { key: 'sixteen', value: 16},
        { key: 'seventeen', value: 17},
        { key: 'eighteen', value: 18},
        { key: 'nineteen', value: 19},
        { key: 'twenty', value: 20},
        { key: 'twenty-one', value: 21},
        { key: 'twenty-two', value: 22},
        { key: 'twenty-three', value: 23},
        { key: 'twenty-four', value: 24},
        { key: 'twenty-five', value: 25},
        { key: 'twenty-six', value: 26},
        { key: 'twenty-seven', value: 27},
        { key: 'twenty-eight', value: 28},
        { key: 'twenty-nine', value: 29},
        { key: 'thirty', value: 30},
    ]
}

const selectionItem = (key, value) => {
    return <MenuItem key={key} value={value}>{value}</MenuItem>;
};
