import {Healers, Melees, Ranged, Tanks} from "../SpecAndClasses/SpecAndClasses";

export const RandomClassSpec = (roleTypes) => {
    let personSingleton = [];
    personSingleton.push(...GetClassAndSpec(1, GetClassAndSpecs(roleTypes)));
    return personSingleton;
}

const GetClassAndSpecs = (roleTypes) => {
    let classes = [];
    if(roleTypes.includes('Healer')) classes.push(...Healers);
    if(roleTypes.includes('Ranged')) classes.push(...Ranged);
    if(roleTypes.includes('Melee')) classes.push(...Melees);
    if(roleTypes.includes('Tank')) classes.push(...Tanks);
    return classes;
};

export const GenerateRaid = (groupSize, roleTypes) => {
    if (groupSize === 1) {
        return RandomClassSpec(roleTypes);
    }
    let raid = [];
    let tankCount = groupSize === 5 ? 1 : 2;
    raid.push(...GetClassAndSpec(tankCount, Tanks));

    let healerCount = CalculateHealerCount(groupSize, tankCount);
    raid.push(...GetClassAndSpec(healerCount, Healers));

    let rangedCount = CalculateRangedCount(groupSize, tankCount, healerCount);
    raid.push(...GetClassAndSpec(rangedCount, Ranged));

    let meleeCount = CalculateMeleeCount(groupSize, tankCount, healerCount);
    raid.push(...GetClassAndSpec(meleeCount, Melees));

    return raid;
};

const GetClassAndSpec = (count, classAndSpecList) => {
    let classAndSpec = [];
    for(let i = 0; i < count; i++) {
        let posn = Math.floor(Math.random() * classAndSpecList.length);
        classAndSpec.push(classAndSpecList[posn]);
    }
    return classAndSpec;
}
const CalculateHealerCount = (groupSize, tanks) => {
    const lessTanks = groupSize - tanks;
    let healers = Math.floor(lessTanks/4);
    if ( lessTanks % 4 === 3 ) {
        healers+=1;
    }
    return healers;
 };
 const CalculateRangedCount = (groupSize, tanks, healers) => {
    let lessTankAndHeals = groupSize - tanks - healers;
    return Math.ceil(lessTankAndHeals/2);
};
const CalculateMeleeCount = (groupSize, tanks, healers) => {
    let lessTanksAndHeals = groupSize - tanks - healers;
    return Math.floor(lessTanksAndHeals/2);
};
