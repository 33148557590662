import {GenerateRaid, RandomClassSpec} from "./GenerateRaid";


export const CreateRaidAndAssignPlayers = (players, roleTypes) => {
    let localPlayers = [...players];
    ShufflePlayers(localPlayers);
    let raid = GenerateRaid(localPlayers.length, roleTypes);
    let count = 0;
    while (!ValidOrdering(raid, localPlayers)) {
        ShufflePlayers(localPlayers)
        count++;
        if( count === localPlayers.length){
            raid = GenerateRaid(localPlayers.length, roleTypes);
            count = 0;
        }
    }
    let response = [];
    for (let index = 0; index < raid.length; index++) {
        response.push(MakeRaider(raid[index], localPlayers[index].name, localPlayers[index].main));
    }
    return response.sort(raidSortByName);
};


export const RerollIndividual = (raider) => {
    let newSpec = RandomClassSpec([raider.classAndSpec.role])[0];
    while (raider.main.includes(newSpec) || raider.classAndSpec === newSpec) {
        newSpec = RandomClassSpec([raider.classAndSpec.role])[0];
    }
    return {...raider, classAndSpec: newSpec };
}

const raidSortByName = (a, b) => {
    if ( a.name < b.name ){
        return -1;
    }
    if ( a.name > b.name ){
        return 1;
    }
    return 0;
}

const MakeRaider = (classAndSpec, name, main) => {
    return {
        classAndSpec,
        name,
        main
    };
};

const ValidOrdering = (raid, players) => {
    let valid = true;
    for ( let index = 0; index < raid.length; index++) {
        const raidClassSpec = raid[index];
        const playerMain = players[index].main;
        console.log(`valid order raid: ${raidClassSpec}`);
        if (playerMain && playerMain.includes(raidClassSpec.textName)) {
            valid = false;
            break;
        }
    }
    return valid;
}

const ShufflePlayers = (players) => {
    console.log("shuffling players...");
    for ( let index = players.length - 1; index > 0; index--) {
        let randomIndex = Math.floor(Math.random() * (index + 1));
        let temp = players[index];
        players[index] = players[randomIndex];
        players[randomIndex] = temp;
    }
    console.log("shuffled!");
};
