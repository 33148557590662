import React, {useCallback, useState} from 'react';
import {Box} from "@mui/material";
import {GeneratedTeam} from "./GeneratedTeam";
import {RaidInput} from "./RaidInput";
import {RaidGenerationSettings} from "./RaidGenerationSettings";


export const Main = () => {
    const [players, setPlayers] = useState([]);
    const [playersLoaded, setPlayersLoaded] = useState(false);
    const [raidSize, setRaidSize] = useState(0);
    const [roles, setRoles] = useState([]);

    const submitHandler = useCallback((playerList) => {
        setPlayers(playerList);
        setPlayersLoaded(true);
    }, [setPlayers, setPlayersLoaded]);
    const handleRaidSizeSetting = useCallback((raidCount, selectRoles) => {
        console.log(`raidCount ${raidCount}`);
        setRaidSize(raidCount);
        console.log(`roles ${selectRoles}`);
        if(selectRoles) setRoles(selectRoles);
    },[setRaidSize, setRoles]);

    return (
        <Box className="App" flex={1} flexDirection={"column"} alignContent={"center"}>
            <Box flex={1} flexDirection={"column"} margin={"auto"}>
                <h1>Random Group Generator</h1>
                <h5>Randomized Specializations, Classes, and Players!</h5>
            </Box>
            {raidSize === 0 ? <RaidGenerationSettings changeRaidSize={handleRaidSizeSetting}/> : playersLoaded ?  <GeneratedTeam playerList={players} roles={roles} /> : <RaidInput submitHandler={submitHandler} raidSize={raidSize}/>}
        </Box>
    );
}
